/* vars */

$base-font-size: 12px;

$page-padding-left: 1cm;
$page-padding-right: 1cm;
$page-padding-top: 1.5cm;
$page-padding-bottom: 1.5cm;


/* imports */

@import 'node_modules/gutenberg-css/scss/variables';

// Normalize
@import '../../node_modules/normalize-scss/sass/normalize/import-now';

@import 'node_modules/gutenberg-css/scss/print-reset';
@import 'node_modules/gutenberg-css/scss/pagination';
@import 'node_modules/gutenberg-css/scss/page';
@import 'node_modules/gutenberg-css/scss/reformat';
@import 'node_modules/gutenberg-css/scss/utilities';

@import "node_modules/gutenberg-css/scss/themes/modern";


/* custom */

.menu__main,
.menu__end,
.menu__social,
.menu__contact,
.header,
.path,
.productinfo__page-options,
#slide-out,
.swiper-slide,
.gallery__thumb,
.productform,
.sale,
.footer__menu,
.footer__parent,
.btmfooter,
.productinfo__price-btn,
pre {
    display: none;
}

.productinfo__label {
    padding: 15px 0;
    font-weight:bold;

    .label:before {
        content:"price: ";
    }
}

.productinfo__table-title {
    font-weight:bold;
}

.footer {
    position: fixed;
    bottom: 60px;
    clear: both;
    font-size:0.8rem;
}

.swiper-wrapper {
    height:auto!important;
}

.swiper-slide-active img {
    width:90%;
}

.swiper-slide-active {
    width:100%!important;
    display: block;
}

.menu__logo {
    margin-bottom:30px;
    img {
        width:150px;
    }
}

.productinfo__gallery,
.productinfo__content {
    float: right;
    width: 50%;
}

